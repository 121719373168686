<template>
  <b-card class="terms-conditions">
    <b-card-header>
      <b-card-header
        ><b-card-title>Terms and Conditions</b-card-title></b-card-header
      >
    </b-card-header>
    <b-card-body>
      <static-content content-code="terms-and-conditions" :showUpdatedAt="true">
        Terms and Conditions not filled yet!
      </static-content>
    </b-card-body>
  </b-card>
</template>

<script>
import StaticContent from "@/layouts/components/static-content-editor/StaticContent";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    StaticContent,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
};
</script>

<style>
.terms-conditions .static-content-container .static-content-float {
  position: relative;
  left: 0;
  bottom: 0;
}
</style>
